import {IConfigState} from "../app/store/slices/config";
import {IServerEvent, ServerEvents} from "../app/store/interfaces/config";

export const parseServerEvents = (serverEvents?: IServerEvent[]) => {
  const configs: Partial<IConfigState> = {};

  if (serverEvents?.length) {
    serverEvents.map((item) => {
      if (item.name === ServerEvents.AppFrontVersion) {
        configs.version = item.value;
      }

      if (item.name === ServerEvents.AppFreeVoteCountdown) {
        configs.freeVoteCountdown = Number(item.value);
      }

      if (item.name === ServerEvents.AppFrontFacebookDisabled) {
        configs.facebookDisabled = Number(item.value);
      }

      if (item.name === ServerEvents.AppFrontEnableRusPayments) {
        configs.enableRusPayments = item.value === "true";
      }

      if (item.name === ServerEvents.AppFrontInstagramRedirect) {
        configs.instagramRedirect = item.value === "true";
      }

      if (item.name === ServerEvents.AppFrontInstagramRedirectModels) {
        configs.instagramRedirectModels = JSON.parse(item.value);
      }

      if (item.name === ServerEvents.AppFrontProfileFeedUserUidList) {
        configs.profileFeedUserUidList = item.value && JSON.parse(item.value);
      }
    });
  }

  return configs;
};
