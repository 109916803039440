import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/app";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import {Provider} from "react-redux";
import {store} from "@app/store/store";
import {LocalStorageKey} from "@appTypes/localstorage";
import * as amplitude from "@amplitude/analytics-browser";
import {sessionReplayPlugin} from "@amplitude/plugin-session-replay-browser";

const isBuild = !window.location?.href?.includes("localhost");

if (isBuild) {
  const amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY;
  const userId =
    localStorage.getItem(LocalStorageKey.UserId) ||
    localStorage.getItem(LocalStorageKey.AnonUserId) ||
    undefined;

  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);

  amplitudeKey &&
    amplitude.init(amplitudeKey, userId, {
      autocapture: true,
    });

  Sentry.init({
    dsn: "https://764d4f78656557ef7b2cae3474ffbebf@o4507972108943360.ingest.de.sentry.io/4507978590519376",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    initialScope: {
      user: {
        id: localStorage.getItem(LocalStorageKey.UserId) || "",
        guestUserId: localStorage.getItem(LocalStorageKey.GuestUserId) || "",
        country: localStorage.getItem(LocalStorageKey.UserCountry) || "",
        anonUserId: localStorage.getItem(LocalStorageKey.AnonUserId) || "",
        isAgeConfirmed:
          localStorage.getItem(LocalStorageKey.IsAgeConfirmed) || "",
      },
    },
    environment: process.env.REACT_APP_NODE_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// TODO: push notifications
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );
      })
      .catch((err) => {
        console.log("Service Worker registration failed:", err);
      });
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
