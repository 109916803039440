import * as amplitude from "@amplitude/analytics-browser";

const isBuild = !window.location?.href?.includes("localhost");

const Amplitude = {
  identifySource: (
    source?: string,
    sourceModel?: string,
    sourceMotivation?: string,
  ) => {
    if (isBuild) {
      const identifyEvent = new amplitude.Identify();

      source &&
        identifyEvent.setOnce(
          "source",
          source.includes("instagram") ? "instagram" : source,
        );
      sourceModel && identifyEvent.setOnce("sourceModel", sourceModel);
      sourceMotivation &&
        identifyEvent.setOnce("sourceMotivation", sourceMotivation);

      amplitude.identify(identifyEvent);
    } else {
      console.log("Set user properties [sources]", {
        source,
        sourceModel,
        sourceMotivation,
      });
    }
  },
  track: (eventType?: string, options = {}) => {
    if (eventType) {
      if (isBuild) {
        amplitude.track(eventType, options);
      } else {
        console.log(eventType, options);
      }
    }
  },
  getUserId: () => {
    if (isBuild) {
      return amplitude.getUserId?.();
    }
  },
  getDeviceId: () => {
    if (isBuild) {
      return amplitude.getDeviceId?.();
    }
  },
  reset: () => {
    if (isBuild) {
      return amplitude.reset?.();
    } else {
      console.log("Amplitude reset");
    }
  },
};

export default Amplitude;
